import MascotImage1 from "../images/nr_sabori_cmyk.jpg";
import MascotImage2 from "../images/nr_kumonashi_cmyk.jpg";
import MascotImage3 from "../images/nr_yubisashi_cmyk.jpg";
import MascotImage4 from "../images/nr_basic_cmyk.jpg";
import MascotImage5 from "../images/nr_aozame_cmyk.jpg";
import MascotImage6 from "../images/nr_punsuko_rgb.jpg";
import MascotImage7 from "../images/nr_kakusei_cmyk.jpg";
import {Grade} from "../types";

export const drawerWidth: number = 240

export enum QuestionType {
    SINGLE='single',
    MULTI='multi',
    MULTI_TABLE='multi_table'
}

export const CHAR_CODE_A=65


export const grades: Grade[] = [
    {
        grade: 0,
        count: 0,
        title: '入門者',
        message: 'バッジをとってニャリックを起こそう！',
        mascot: MascotImage1.src
    },
    {
        grade: 1,
        count: 1,
        title: '初級者',
        message: 'バッジをとるとニャリックが賢くなるよ。',
        mascot: MascotImage2.src
    },
    {
        grade: 2,
        count: 5,
        title: '駆け出し',
        message: 'バッチをとったらニャリック on Cloud',
        mascot: MascotImage3.src
    },
    {
        grade: 3,
        count: 10,
        title: 'トレーナー',
        message: 'バッチをとったら、ニャリック完全に理解',
        mascot: MascotImage4.src
    },
    {
        grade: 4,
        count: 20,
        title: 'ナイト',
        message: 'バッチをとったら、ニャリックが覚醒Lv1',
        mascot: MascotImage5.src
    },
    {
        grade: 5,
        count: 35,
        title: 'エリート',
        message: 'バッチをとったら、ニャリックが覚醒Lv2',
        mascot: MascotImage6.src
    },
    {
        grade: 6,
        count: 100,
        title: 'マスター',
        message: 'バッチをとったら、ニャリックが覚醒Lv3',
        mascot: MascotImage7.src
    },
    {
        grade: 7,
        count: 200,
        title: 'グランド・マスター',
        message: '',
        mascot: MascotImage3.src
    },
    {
        grade: 8,
        count: 1000000,
        title: '魔法使い',
        message: '',
        mascot: MascotImage4.src
    },
]

export const MAX_TIMESTAMP = 2099999999999
import {InputField} from '../types/';

export const getFieldByPropName = <T>(fields : InputField<T>[], propName: string): InputField<T> | undefined => {
    return fields.find(field => field.propName === propName)
}

export const isIndex = (pathname: string): boolean => {
    return pathname === '/'
}

export const allowWIP = (institution = '', wip = false)=> {
    return institution === 'NEWRELIC' || !wip
}